import React, { useState } from 'react'
// import { InputField } from './newsletter/SignupForm'
import { form, formActive, formInner, labelText, inputField, selectWrapper, selectField, textareaField, submitButton } from "../styles/forms.module.scss"
import axios from "axios"
import AcceptBox from './AcceptBox';
import Loading from '../svg/Loading';

export default function ContactForm(props) {


    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
      });
      const handleServerResponse = (ok, msg, cForm) => {
        setServerState({
          submitting: false,
          status: { ok, msg }
        });
        if (ok) {
          cForm.reset();
        }
      };

    //   const handleValidation = () => {
    //       if (yurp) {
    //           handleServerResponse(false, "Error. Please try again.", cForm)
    //       }
    //   }

    //   const getFormEp = "https://getform.io/f/b7bd3c86-b859-477e-befc-ef2dce3d17a5";
    const ltaEndpoint = "https://media.lighterair.com/wp-json/contact-form-7/v1/contact-forms/1106/feedback";


      const handleOnSubmit = e => {
        e.preventDefault();
        const cForm = e.target;
        setServerState({ submitting: true });
        axios({
          method: "post",
          url: ltaEndpoint,
          data: new FormData(cForm)
        })
          .then(r => {
            handleServerResponse(true, "Thanks. We'll be in touch soon.", cForm);
          })
          .catch(r => {
            handleServerResponse(false, r.response.data.error, cForm);
          });
      };


    const subjectOptions = [
        {
            name: `General inquiry`,
            value: `general`
        },
        {
            name: `Question about an artist`,
            value: `artist-query`
        },
        {
            name: `Re: LTA Records`,
            value: `records`
        },
        {
            name: `Other`,
            value: `other`
        }
    ]

    let formClassName = form;
    if (serverState.submitting === true) formClassName = [form, formActive].join(" ");


    return (
        <form className={formClassName} onSubmit={handleOnSubmit}>
            <div className={formInner}>
                <div className={`flex flex-row md:justify-start md:items-end -mx-2 md:-mx-3 lg:-mx-4 xl:-mx-5 flex-wrap`}>
                
                <div className={`px-2 md:px-3 lg:px-4 xl:px-5 flex-1/2 max-w-1/2 w-full`}>


                <label className="inputField__label">
                    <span className={labelText}>First Name</span>
                    <input
                        type={`text`}
                        placeholder={`Tommy`}
                        required={true}
                        className={inputField}
                        name={`first-name`}
                    />
                </label>
                </div>

                <div className={`px-2 md:px-3 lg:px-4 xl:px-5 flex-1/2 max-w-1/2 w-full`}>

                <label className="inputField__label">
                    <span className={labelText}>Last Name</span>
                    <input
                        type={`text`}
                        placeholder={`Shelby`}
                        required={true}
                        className={inputField}
                        name={`last-name`}
                    />
                </label>
                </div>

                <div className={`sm:mt-3 mt-4 px-2 md:px-3 lg:px-4 xl:px-5 lg:max-w-3/5 w-full`}>
                            <div className={`w-full`}>

                    <label className="inputField__label">
                    <span className={labelText}>Email</span>
                    <input
                        type={`email`}
                        placeholder={`thomas.shelby@email.com`}
                        required={true}
                        className={inputField}
                        name={`email`}
                    />
                </label>
                            </div>
                </div>

                <div className={`sm:mt-3 mt-4 px-2 md:px-3 lg:px-4 xl:px-5 lg:max-w-2/5 w-full`}>
                            <div className={`w-full`}>


                            <div className={selectWrapper}>
                                    <label className="inputField__label" htmlFor={`subject`}><span className={labelText}>Subject</span></label>
                                    <select
                                    className={selectField}
                                    name={`subject`}
                                    id={`subject`}
                                    >
                                        {subjectOptions.map((opt, index) => (
                                            <option key={index}  value={opt.name}>{opt.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                </div>

                <div className={`sm:mt-3 mt-4 px-2 md:px-3 lg:px-4 xl:px-5`} style={{visibility: 'hidden', opacity: '0', position: 'absolute', zIndex: '-1'}}>
                    <div className={`w-full`}>
                    <label className="inputField__label">
                        <span className={labelText}>Site Url</span>
                        <input
                        type={`url`}
                        placeholder={`google.com`}
                        required={false}
                        className={inputField}
                        name={`person-site`}
                        autoComplete={`off`}
                    />
                    </label>
                    </div>
                </div>

                <div className={`sm:mt-3 mt-4 px-2 md:px-3 lg:px-4 xl:px-5 w-full`}>
                            <div className={`w-full`}>

                    <label className="inputField__label">
                        <span className={labelText}>Message</span>
                        <textarea
                            required={true}
                            className={textareaField}
                            rows={18}
                            name={`message`}
                        />
                    </label>
                    </div>
                </div>
                </div>

                    <input
                    className={[submitButton, 'dark:bg-primary-default', 'dark:text-white'].join(" ")}
                    type="submit"
                    value="send message"
                    // disabled={validateInput(props.formValues)}
                />
        
            <AcceptBox name={`acceptance`}>
                By leaving this box checked, you are accepting the terms detailed in our <a className={`underline relative underline-offset-2 z-10`} href={`https://media.lighterair.com/privacy-policy/`} target={`_blank`} rel={`noopener noreferrer`} title={`Privacy Policy`}>Privacy Policy</a>.
            </AcceptBox>
         </div>
        {serverState.status && (
                            <div className="pt-4 text-center text-sm 2xl:text-base">
                                    <span className={`${!serverState.status.ok ? "errorMsg" : ""} font-bold tracking-normal`}>
                                    {serverState.status.msg}
                                    </span>
                            </div>
                                )}

        {serverState.submitting === true &&
            <Loading className={`!w-32 !h-32 absolute top-0 right-0 left-0 bottom-0 m-auto`} />
        }
        </form>
        
    )
}